import * as Sentry from '@sentry/browser';

function shouldIgnoreError(error) {
  return isProbablyBlockedByAdblockNetworkError(error);
}

function isProbablyBlockedByAdblockNetworkError(error) {
  const now = Date.now();

  if (!error.breadcrumbs) {
    return false;
  }

  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb = error.breadcrumbs[i];
    if (!breadcrumb) continue;

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
      break;
    }

    if (isProbablyBlockedByAdblockBreadcrumb(breadcrumb)) {
      return true;
    }
  }

  return false;
}

function isProbablyBlockedByAdblockBreadcrumb(breadcrumb) {
  if (
    breadcrumb.level !== 'error' ||
    (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')
  ) {
    return false;
  }

  const url = breadcrumb.data?.url;
  if (!url) return false;

  return isProbablyBlockedByAdblockUrl(url);
}

function isProbablyBlockedByAdblockUrl(url) {
  const adblockPattern = /adblock/;
  const googleAnalyticsPattern = /https?:\/\/(www\.)?google-analytics\.com\/(collect(\?.*)?|analytics\.js|gtag\/js|gtm\.js(\?.*)?|fbevents\.js|ga4-[\w-]+\.js)/;
  const batPattern = /bat\.js/;

  return adblockPattern.test(url) || googleAnalyticsPattern.test(url) || batPattern.test(url);
}

Sentry.init({
  dsn: window.SENTRY_DSN_FRONTEND,
  environment: window.SENTRY_ENVIRONMENT,
  release: window.SENTRY_RELEASE,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  debug: window.SENTRY_ENVIRONMENT === 'development',
  beforeSend: (event) => {
    return shouldIgnoreError(event) ? null : event;
  },
  // This is a list of URLs that we want to allow to be sent to Sentry to prevent false positives
  allowUrls: [/https?:\/\/((prod-cdn|staging-cdn|www)\.)?laundryheap\.com/],
  ignoreErrors: [
    /Non-Error promise rejection/, 
    /Fetch globalNotice timeout/],
});

window.Sentry = Sentry;
